import React from 'react';
import './App.css';

type AppProps = {
  priceJPY: number;
};

const App: React.FC<AppProps> = ({ priceJPY: priceYen }: AppProps) => (
  <div className="App">
    <div className="AppContainer">
      <div className="priceContiner">
        <span className="priceUnitJPY">￥</span>
        <span className="price">{priceYen}</span>
      </div>
      <div className="payVendorList">
        <button className="payVendorLink" type="button">PayPay</button>
        <button className="payVendorLink" type="button">LINEPay</button>
      </div>
    </div>
  </div>
);

export default App;
