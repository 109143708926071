import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

function initialize() {
  const url = new URL(document.location.href);
  const price = url.searchParams.get('price');
  if (price == null) {
    // TODO: error handling
    return;
  }
  ReactDOM.render(<App priceJPY={
    Number.parseInt(price, 10)
  }
  />, document.getElementById('root'));
}

initialize();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
